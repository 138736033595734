<template>
  <div>
    <AddHeaderTitle :Header="Header" />
    <AppHeaderInfoAdminList :infos="infos" />

    <label>Titre</label>
    <b-form-input v-model="surveyData.name" placeholder="Taper le nom du survey"></b-form-input>
    <small v-if="!surveyData.name" class="text-danger">Ce champ ne peut pas être vide</small>

    <br />

    <label>Description</label>
    <b-form-input v-model="surveyData.description" placeholder="Enter your name"></b-form-input>
    <br />

    <div class="text-center">
      <b-button variant="success" @click="update()">Enregistrer les modifications</b-button>
    </div>

    <b-row align-h="start">
      <b-button variant="primary " class="mb-2 mr-2" v-b-modal.modal-survey-question-create>
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span class="align-left">Créer une question</span>
      </b-button>
    </b-row>

    <b-table striped hover :items="surveyData.questions" :fields="fields">
      <template #cell(index)="data">{{ data.index + 1 }}</template>

      <template #cell(choices)="question">
        <div v-for="choice in question.item.choices" :key="choice.name">{{ choice.name }}</div>
      </template>

      <template #cell(action)="list">
        <b-button
          variant="flat-primary"
          size="sm"
          v-b-modal.modal-survey-question-edit
          @click="setDataSurvey(list.item)"
        >
          <feather-icon icon="EditIcon" />
        </b-button>

        <b-button
          v-b-modal.modal-sm
          @click="sendInfo(list.item)"
          variant="flat-danger"
          class
          size="sm"
        >
          <feather-icon icon="Trash2Icon" />
        </b-button>
      </template>
    </b-table>

    <b-modal id="modal-sm" centered size="sm" title="Information">
      <template #default>Êtes-vous sûr de vouloir supprimer la question "{{ question.title }}" ?</template>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="primary" @click="cancel()">Annuler</b-button>
        <b-button size="sm" variant="danger" @click="deleteQuestionId(question.id)">Supprimer</b-button>
      </template>
    </b-modal>

    <survey-question-edit-modal :surveyQuestion="surveyQuestion" :surveyData="surveyData"></survey-question-edit-modal>
    <survey-question-create-modal @clicked="onCreate" :surveyData="surveyData"></survey-question-create-modal>
  </div>
</template>

<script>
import AddHeaderTitle from "@/app/views/components/AppHeaderTitle.vue";
import AppHeaderInfoAdminList from "@/app/views/components/AppHeaderInfoAdminList.vue";
import { BTable, BButton, BRow, BFormInput } from "bootstrap-vue";

import SurveyQuestionCreateModal from "./survey-question-create-modal.vue";
import SurveyQuestionEditModal from "./survey-question-edit-modal.vue";

import { Admin } from "@/mixins/admin.js";

export default {
  name: "Survey-detail",

  components: {
    BTable,
    AddHeaderTitle,
    AppHeaderInfoAdminList,
    BButton,
    BRow,
    BFormInput,
    SurveyQuestionCreateModal,
    SurveyQuestionEditModal,
  },

  data() {
    return {
      Header: {
        config: "Admin",
        listLink: "",
        list: "Speaker-liste",
      },
      infos: {
        title: "Edition d'un survey",
        content: "Texte descptif ",
      },

      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        {
          key: "index",
          label: "N°",
          sortable: false,
          thStyle: { width: "3%" },
        },
        {
          key: "title",
          label: "Questions",
          sortable: true,
        },
        {
          key: "choices",
          label: "Proposition",
          sortable: true,
        },

        {
          key: "type",
          label: "Type",
          sortable: true,
        },

        {
          key: "Action",
          label: "Action",
          sortable: false,
          thClass: "text-right",
          tdClass: "text-right",
          thStyle: { width: "3%" },
        },
      ],
      surveyId: this.$route.params.id,
      surveyData: {},
      surveyQuestion: {},
      question: {},
      listSpeaker: [],
      selected: "",
    };
  },

  mixins: [Admin],

  mounted() {
    this.loadList();
  },

  methods: {
    onCreate(value) {
      this.surveyData.questions.push(value);
    },

    async loadList() {
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: true,
      });
      try {
        const response = await this.getSurvey(this.surveyId);
        if (response.status == 1) {
          this.surveyData = response.datas;
        } else {
          this.$sweetError("AF-52");
        }
        loader.hide();
      } catch {
        loader.hide();
        this.$sweetError("AF-52");
      }
    },

    setDataSurvey(surveyData) {
      this.surveyQuestion = surveyData;
    },

    async update() {
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: true,
      });
      if (this.surveyData.name) {
        try {
          const response = await this.updateSurvey(
            this.surveyData.id,
            this.surveyData.name,
            this.surveyData.description,
            this.surveyData.type
          );
          if (response.status == 1) {
            this.$sweetNotif("Modification réussie");
          } else {
            this.$sweetError("AF-51");
          }
          loader.hide();
        } catch {
          loader.hide();
          this.$sweetError("AF-51");
        }
      } else {
        loader.hide();
      }
    },

    sendInfo(questionInfo) {
      this.question = questionInfo;
    },

    async deleteQuestionId(idQuestion) {
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
        canCancel: true,
      });
      this.$bvModal.hide("modal-sm");
      try {
        const res = await this.deleteSurveyQuestion(this.surveyData.id, idQuestion);
        if (res.status == 1) {
          this.$sweetNotif("Modification réussie");
          this.surveyData.questions = this.surveyData.questions.filter((question) => question.id !== idQuestion);
        } else {
          this.$sweetError("AF-51");
        }
        loader.hide();
      } catch {
        loader.hide();
        this.$sweetError("AF-51");
      }
    },

  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

