var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal-survey-question-create",attrs:{"id":"modal-survey-question-create","title":"Création"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.onSubmit}},[_vm._v(" Enregistrer ")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"danger","size":"sm mr-1"},on:{"click":_vm.hideModal}},[_vm._v(" Fermer ")])],1)]},proxy:true}])},[_c('ValidationObserver',{ref:"observer"},[_c('b-form',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Question ")]),_c('b-form-input',{attrs:{"placeholder":"Tapez votre question"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('br'),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Type de question ")]),_c('b-form-select',{staticClass:"mb-2",attrs:{"options":_vm.options},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.form.type == 'QCM')?_c('b-form-tags',{staticClass:"mb-2",attrs:{"no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputId = ref.inputId;
var tagVariant = ref.tagVariant;
var addTag = ref.addTag;
var removeTag = ref.removeTag;
return [_c('b-input-group',{staticClass:"mb-2"},[_c('b-form-input',{staticClass:"form-control",attrs:{"id":inputId,"placeholder":"Ajouter les propositions du QCM"},model:{value:(_vm.newTag),callback:function ($$v) {_vm.newTag=$$v},expression:"newTag"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return addTag(_vm.newTag)}}},[_vm._v("Ajouter")])],1)],1),_c('div',{staticClass:"d-inline-block",staticStyle:{"font-size":"1.5rem"}},_vm._l((tags),function(tag){return _c('b-form-tag',{key:tag,staticClass:"mr-1",attrs:{"title":tag,"variant":tagVariant},on:{"remove":function($event){return removeTag(tag)}}},[_c('p',{staticStyle:{"white-space":"break-spaces"}},[_vm._v(" "+_vm._s(tag)+" ")])])}),1)]}}],null,false,737597469),model:{value:(_vm.choices),callback:function ($$v) {_vm.choices=$$v},expression:"choices"}}):_vm._e(),(_vm.form.type == 'QCM' && this.choices.length < 2)?_c('small',{staticClass:"text-danger"},[_vm._v("Il faut minimum 2 propositions pour un QCM")]):_vm._e(),_c('br'),_c('br'),_c('label',[_vm._v(" Information ")]),_c('b-form-input',{attrs:{"placeholder":"Info supplémentaire"},model:{value:(_vm.form.infos),callback:function ($$v) {_vm.$set(_vm.form, "infos", $$v)},expression:"form.infos"}}),_c('br')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }