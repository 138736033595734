<template>
  <div>
    <b-modal
      ref="modal-survey-question-create"
      id="modal-survey-question-create"
      title="Création"
    >
      <!-- Media -->
       <ValidationObserver ref="observer">
        <b-form>

          <ValidationProvider rules="required" v-slot="{ errors }">
            <label> Question </label>
            <b-form-input v-model="form.title" placeholder="Tapez votre question"></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider> 

          <br>

          <ValidationProvider rules="required" v-slot="{ errors }">
            <label> Type de question </label>
            <b-form-select class="mb-2" v-model="form.type" :options="options"></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider> 

          <b-form-tags v-if="form.type == 'QCM'" v-model="choices" no-outer-focus class="mb-2"> 
            <template v-slot="{tags, inputId, tagVariant, addTag, removeTag}">
              <b-input-group class="mb-2">
                <b-form-input
                   :id="inputId"
                   v-model="newTag"
                  placeholder="Ajouter les propositions du QCM"
                  class="form-control"
                ></b-form-input>
                <b-input-group-append> <b-button @click="addTag(newTag)" variant="primary">Ajouter</b-button> </b-input-group-append>
              </b-input-group>

              <div class="d-inline-block" style="font-size: 1.5rem">
                <b-form-tag
                  v-for="tag in tags"
                  @remove="removeTag(tag)"
                  :key="tag"
                  :title="tag"
                  :variant="tagVariant"
                  class="mr-1"
                  >
                    <p style="white-space: break-spaces;">  {{ tag }} </p>
                  </b-form-tag>
              </div>
            </template>
              
          </b-form-tags>
          <small v-if="form.type == 'QCM' && this.choices.length < 2" class="text-danger">Il faut minimum 2 propositions pour un QCM</small>
          <br><br> 

          <label> Information </label>
          <b-form-input v-model="form.infos" placeholder="Info supplémentaire"></b-form-input>
          <br>

        </b-form>
      </ValidationObserver>
      <template #modal-footer>
        <div class="w-100">
          
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="onSubmit"
          >
            Enregistrer
          </b-button>

          <b-button
            variant="danger"
            size="sm mr-1" 
            class="float-right"
            @click="hideModal"
          >
            Fermer
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  BForm,
  BFormSelect,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormTags,
  BFormTag,
} from "bootstrap-vue";
import { Admin } from "@/mixins/admin.js";

import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

extend("required", { message: "Ce champ ne peut pas être vide" });



export default {
  name: "SurveyQuestionCreateModal",

  props: {
    surveyData: {},
  },

  components: {
    BModal,
    BButton,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BFormTags,
    BFormTag,
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [Admin],

  data() {
    return {
      newTag: '',
      form : {
        choices: []
      },
      selected: "",
      options: [
        { value: "TEXT_AREA", text: "Question ouverte" },
        { value: "QCM", text: "QCM" },
        { value: "RATING", text: "Rating" },
      ],
      show: false,
      choices: [],
    };
  },

  methods: {
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      const isValidQCM = this.valideQCM();

      if (isValid && isValidQCM) {
        if(!this.form.type == "QCM") {
          this.form.choices = [];
        }else{
          this.form.choices = this.choices.map(name => ({ name: name }));
        }
        
         let loader = this.$loading.show({
          container: this.$refs.formContainer,
          canCancel: true,
        });
        
         try {
        const response = await this.createSurveyQuestion(this.surveyData.id, this.form);
        if (response.status == 0) {
          //@dev alert pour dire qu'il y a eu un probleme
          console.log("erreur");
        } else {
          this.$emit("clicked", response.datas);
          this.form = {};
          this.choices = [];
          this.newTag= '';
           loader.hide();
            this.$sweetNotif("Modification réussie");
          this.$refs["modal-survey-question-create"].hide();
        }
        } catch {
          loader.hide();
          this.$sweetError("AF-51");
        }
      }
    },

    valideQCM() {
      let result = true;
      if(this.form.type == "QCM") {
        if(this.choices.length < 2) {
          result = false;
        }
      }
      return result;
    },



    hideModal() {
      this.$refs["modal-survey-question-create"].hide();
      this.newTag= '';
    },
  },


};
</script>

<style>
</style>